import React from 'react';
import "../css/TermService.css"
const PrivacyPolicy = () => {
    return (
        <div  className=''>
                <div className='text_container'>
                <iframe title="privacy policy" src="Grav.id - Privacy Policy (Official).html"></iframe>
                </div>
        
        </div>
    );
};

export default PrivacyPolicy;